<template>
  <v-container fluid>
    <v-card>
      <v-toolbar
        color="secondary"
        flat
      >
        <v-toolbar-title>Incoming Payments</v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- <v-btn
          color="accent"
          small
          to="/banking/new-incoming-payment"
        >
          <v-icon
            left
            dark
          >mdi-plus</v-icon>New Incoming Payment
        </v-btn> -->

        <v-spacer></v-spacer>

        <v-col cols="2">
          <v-btn
            icon
            class="ml-10"
            right
            @click="getDocData"
          >
            <v-icon>mdi-sync</v-icon>
          </v-btn>
        </v-col>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="search"
              append-icon="mdi-search"
              label="Search"
              single-line
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="12"
          >
            <v-data-table
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="docData"
              :search="search"
            >
              <!-- start of posting date template -->
              <template v-slot:item.DocDate="{ item }">{{ item.DocDate | moment("Do MMM YYYY")}}</template>
              <!-- end of posting date template -->

              <!-- start of delivery date template -->
              <template v-slot:item.DocDueDate="{ item }">{{ item.DocDueDate | moment("Do MMM YYYY")}}</template>
              <!-- end of delivery date template -->

              <!-- start of document date template -->
              <template v-slot:item.TaxDate="{ item }">{{ item.TaxDate | moment("Do MMM YYYY")}}</template>
              <!-- end of document date template -->

              <!-- start of doc total template -->
              <template v-slot:item.DocTotal="{ item }">{{ item.DocTotal.toLocaleString()}}</template>
              <!-- end of doc total template -->

              <template v-slot:item.syncStatus="{ item }">
                <v-btn
                  @click="openerrorDocDialog(item)"
                  color="red"
                  icon
                  v-show="item.withErrors == true"
                >
                  <v-icon>mdi-alert</v-icon>
                </v-btn>
                <v-btn
                  color="green"
                  icon
                  v-show="item.withErrors == false"
                >
                  <v-icon>mdi-check</v-icon>
                </v-btn>

                <v-btn
                  color="grey"
                  icon
                  v-show="item.withErrors == 'upload'"
                >
                  <v-icon>mdi-upload</v-icon>
                </v-btn>
              </template>

              <!-- action template -->
              <template v-slot:item.action="{ item }">
                <v-btn
                  icon
                  :to="`/banking/incoming-payments/${item.id}`"
                >
                  <v-icon color="green">mdi-eye</v-icon>
                </v-btn>
              </template>
              <!-- end of action template -->
            </v-data-table>
          </v-col>
        </v-row>
        <snackbar ref="snackbar"></snackbar>
      </v-card-text>
    </v-card>

    <!-- category quick add dialog -->
    <v-dialog
      v-model="errorDocDialog"
      max-width="650px"
    >
      <v-card>
        <v-toolbar
          dense
          color="red"
          dark
        >
          <v-toolbar-title>Sync Process Error Message</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click.native="errorDocDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                This document was not sync to SAP due to below following error:

              </v-col>
              <v-col cols="12">

                <span>
                  {{this.ErrorMessage}}
                </span>

              </v-col>

              <v-row>
                <v-col cols="6">
                  <v-btn
                    color="green"
                    dark
                    @click="errorDocDialog = false"
                  >Close</v-btn>
                </v-col>
              </v-row>
            </v-row>

          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end of modal-->

  </v-container>
</template>

<script>
export default {
  data: () => ({
    search: "",
    docData: [],
    errorDocDialog: false,
    ErrorMessage: null,
    loading: false,
    headers: [
      { text: "", value: "syncStatus" },
      { text: "SAP #", value: "ExtRefDocNum" },
      { text: "Customer Name", value: "CardName" },
      { text: "Posting Date", value: "DocDate" },
      { text: "Due Date", value: "DocDueDate" },
      { text: "Document Date", value: "TaxDate" },
      { text: "Doc. Total", value: "DocTotal" },
      { text: "View More", value: "action" },
    ],
  }),
  methods: {
    getDocData() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/incoming_payment`)
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.docData = res.ResponseData;
            self.loading = false;
          }
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    openerrorDocDialog(item) {
      this.ErrorMessage = item.ErrorMessage;
      this.errorDocDialog = true;
    },
  },
  created() {
    this.getDocData();
  },
};
</script>